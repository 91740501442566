'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

class CarIndexedReader {
  static async fromFile() {
    throw new Error('Unsupported in this environment');
  }
}
const __browser = true;

exports.CarIndexedReader = CarIndexedReader;
exports.__browser = __browser;
